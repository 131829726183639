import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from "@/views/LoginPage";
import ExhibitionMatch from "@/views/ExhibitionMatch";
import MatchPredictions from "@/views/MatchPredictions";
import store from '../store/index'

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: 'NotFound',
    redirect: '/',
  },
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/match',
    name: 'ExhibitionMatch',
    component: ExhibitionMatch
  },
  {
    path: '/predictions',
    name: 'MatchPredictions',
    component: MatchPredictions
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  tealiumTagging(to);
  if (to.name !== 'LoginPage' && !store.state.msisdn) next({name: 'LoginPage'});
  else next();
})

function tealiumTagging(to) {
    const utagData = {
      page_name: window.location.host,
      page_section: to.fullPath,
      page_category: '',
      page_subcategory: '',
      page_type: 'Article',
      page_title: document.title,
      page_url: window.location.href,
      page_referrer: document.referrer,
      customer_id: '',
      customer_username: '',
      customer_country: window.navigator.language.split('-').reverse()[0],
      site_region: window.navigator.language,
      article_id: '',
      article_name: '',
      video_id: '',
      video_name: '',
      video_player: '',
      user_platform: window.clientInformation.platform,
      // connection_type: window.clientInformation.connection.effectiveType,
      team_id: '',
      page_event: '',
      ad_requests: '',
      page_channel: '',
      page_platform: '',
      am_report_suite: '',
      page_section_lvl1: '',
      page_section_lvl2: '',
      page_section_lvl3: '',
      page_section_lvl4: '',
      page_section_lvl5: ''
    };


    const pagePath = to.fullPath === '/' ? window.location.origin : `${window.location.origin}/${to.fullPath}`
    window.gtag('config', '6815c970-907c-4d5a-930b-582939f601bb', {
      page_path: `${pagePath}`,
    });

    if (to.fullPath === '/') {
      const a = 'https://tags.tiqcdn.com/utag/vodafone/za-nxtvl-main/prod/utag.js';
      const b = document;
      const c = 'script';
      const d = b.createElement(c);
      d.src = a;
      d.type = 'text/java' + c;
      d.async = true;
      const e = b.getElementsByTagName(c)[0];
      e.parentNode.insertBefore(d, e);
      window.utag_data = utagData;
    }

}

export default router;
