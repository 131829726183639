import axios from "axios";

export default class ExhibitionMatchService {
    static async checkExhibitionGameEntries(msisdn) {
        return await axios.post(`${process.env.VUE_APP_FANTASY_API}/exhibition/check-game-entries`, {msisdn: msisdn})
    }

    static async postExhibitionGameEntries(msisdn, team1Score, team2Score) {
        return await axios.post(`${process.env.VUE_APP_FANTASY_API}/exhibition/post-game-entries`, {
            msisdn,
            team1Score,
            team2Score
        })
    }

    static async countExhibitionGameEntries() {
        const response = await axios.post(`${process.env.VUE_APP_FANTASY_API}/exhibition/count-game-entries`)
        return response;
    }
}
