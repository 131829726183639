<template>
  <div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100 mt-4">
    <div class="header-text-box">Champions League Final</div>
    <div class="match-container">
      <p class="date-time-text">28 May 21:00</p>
      <div class="teams-box">
        <div class="team-logo-box"><img class="team-badge" src="../assets/real-madrid-badge.png" alt="">
          <p class="team-text">Real Madrid</p></div>
        <div class="vs-text-box"><p><strong class="date-time-text">VS</strong></p></div>
        <div class="team-logo-box"><img class="team-badge" src="../assets/liverpool-badge.png" alt="">
          <p class="team-text">Liverpool</p></div>
      </div>
      <div class="timer-container">
        <div class="timer-box">
          <div class="timer-content">
            <img class="whistle-icon" src="../assets/whistle-icon.png" alt="">
            <div class="whistle-timer-text">{{ getTimeTillKickOff() }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-text-prediction">Score prediction:</div>
    <div class="score-prediction-container">
      <div class="team-prediction-box">
        <p class="team-text">Real Madrid</p>
        <div class="prediction-buttons">
          <div class="circle-background" v-on:click="changeScore('-', 1)"><img class="plus-minus"
                                                                               src="../assets/minus-icon.png"/></div>
          <div class="prediction-number">{{ team1Score }}</div>
          <div class="circle-background" v-on:click="changeScore('+', 1)"><img class="plus-minus" src="../assets/plus-icon.png"/></div>
        </div>
      </div>
      <div class="team-prediction-box">
        <p class="team-text">Liverpool</p>
        <div class="prediction-buttons">
          <div class="circle-background" v-on:click="changeScore('-', 2)">
            <img class="plus-minus" src="../assets/minus-icon.png"/>
          </div>
          <div class="prediction-number">{{ team2Score }}</div>
          <div class="circle-background" v-on:click="changeScore('+', 2)">
            <img class="plus-minus" src="../assets/plus-icon.png"/>
          </div>
        </div>
      </div>
    </div>
    <button class="enter-button" v-on:click="onEnterClicked">ENTER</button>
    <div class="d-flex flex-column justify-content-center mt-5">
      <p class="terms-conditions-text">
        <a href="https://termsandconditions.vodacomfantasy.com/">T's and C's Apply</a>
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ExhibitionMatchService from "@/services/exhibitionMatchService";
import {mapState} from 'vuex';

export default {
  name: "ExhibitionMatch",
  data() {
    return {
      kickOffDateTime: '2022-05-28T21:00:00+02:00',
      team1Score: 0,
      team2Score: 0,
    }
  },
  computed: {
    ...mapState(['msisdn'])
  },
  methods: {
    getTimeTillKickOff() {
      const dur = moment.duration(moment(this.kickOffDateTime).diff(moment()));

      const daysRemain = dur.days();
      const hoursRemain = dur.hours();
      const minutesRemain = dur.minutes();
      const secondsRemain = dur.seconds()
      if (daysRemain > 0) return `${daysRemain < 10 ? 0 : ''}${daysRemain}d${hoursRemain < 10 ? 0 : ''}${hoursRemain}h`
      if (daysRemain < 0 && hoursRemain > 1) return `${hoursRemain < 10 ? 0 : ''}${hoursRemain}${minutesRemain < 10 ? 0 : ''}${minutesRemain}m`
      if (hoursRemain <= 1) return `${minutesRemain < 10 ? 0 : ''}${minutesRemain}m${secondsRemain < 10 ? 0 : ''}${secondsRemain}s`
    },
    changeScore(mathSymbol, team) {
      if (mathSymbol === '-') {
        if (team === 1 && this.team1Score !== 0) this.team1Score--;
        if (team === 2 && this.team2Score !== 0) this.team2Score--;
      }
      if (mathSymbol === '+') {
        if (team === 1) this.team1Score++;
        else this.team2Score++;
      }
    },
    async onEnterClicked() {
      await ExhibitionMatchService.postExhibitionGameEntries(this.msisdn, this.team1Score, this.team2Score);
      this.$router.push('/predictions');
    }
  }
}
</script>

<style scoped>
.header-text-box {
  margin-left: 50px;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 700;
}

.header-text-prediction {
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 50px;
}

.match-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #E3151C;
  border-radius: 10px 10px 10px 10px;
  height: 200px;
  width: 100%;
  box-shadow: 0px 2px 9px 4px darkgrey;
  padding: 25px;
}

.score-prediction-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}

.team-prediction-box {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  background-color: #E3151C;
  border-radius: 10px 10px 10px 10px;
  width: 48%;
  height: 100%;
  box-shadow: 0px 2px 9px 4px darkgrey;
  margin: 0;
}

.date-time-text {
  color: white;
}

.team-text {
  color: white;
  opacity: 0.8;
  margin: 0;
  text-align: center;
}

.team-badge {
  height: 60%;
  margin-left: 40px;
  margin-right: 40px;
}

.team-logo-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.teams-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.timer-container {
  position: relative;
}

.timer-box {
  position: absolute;
  height: 20px;
  width: 80px;
  background-color: #E3151C;
  border-radius: 0px 0px 10px 10px;
  bottom: -24px;
  left: -40px;
}

.timer-content {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%
}

.whistle-icon {
  height: 20px;
  margin-left: 4px;
}

.whistle-timer-text {
  display: flex;
  align-items: center;
  font-size: x-small;
  color: white;
  margin-left: 5px;
}

.circle-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: white;
}

.prediction-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.prediction-number {
  font-size: xx-large;
  color: white;
  margin: 10px;
  font-weight: 600;
  height: 50px;
}

.plus-minus {
  height: 15px;
}

.enter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: 50px;
  width: 100%;
  background-color: black;
  border-radius: 10px 10px 10px 10px;
  color: white;
  font-weight: 600;
  border: none;
}


@media (max-width: 340px) {
  .team-badge {
    height: 40%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .team-text {
    font-size: small;
  }

  .prediction-number {
    margin: 5px;
  }
}
</style>
