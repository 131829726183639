import { createStore } from 'vuex'

export default createStore({
  state: {
    msisdn: undefined,
    playerPredictions: undefined,
    totalPredictionCount: undefined,
  },
  getters: {
  },
  mutations: {
    setMsisdn(state, msisdn) {
      state.msisdn = msisdn;
    },
    setPlayerPredictions(state, playerPredictions) {
      state.playerPredictions = playerPredictions;
    },
    setTotalPredictionCount(state, totalPredictionCount) {
      state.totalPredictionCount = totalPredictionCount
    }
  },
  actions: {
  },
  modules: {
  }
})
