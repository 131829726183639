<template>
  <div class="container-fluid d-flex flex-column justify-content-evenly align-items-center">
    <div class="d-flex flex-column justify-content-center align-items-center w-100 top-margin">
      <div class="header-text">Congratulations!</div>
      <div class="line-break"></div>
      <div class="body-text">You have been entered into the R10 000 draw!</div>
    </div>
    <div class="predictions-banner">
      <div class="team-flag real-madrid-flag" :style="{width: team1FlagWidth + '%'}">
        <dev class="percentage-real-madrid">{{Math.trunc(team1FlagWidth)}}%</dev>
      </div>
      <div class="team-flag liverpool-flag" :style="{width: team2FlagWidth + '%'}">
        <dev class="percentage-liverpool">{{Math.trunc(team2FlagWidth)}}%</dev>
      </div>
    </div>
    <button class="enter-button" v-on:click="onChangePredictionsClicked">Change Predictions</button>
    <button class="enter-button mt-3" v-on:click="onSignUpClicked">Sign Up</button>
    <div class="d-flex flex-column justify-content-center mt-2">
      <p class="body-text">
        Sign up to VIP and stand a chance to win weekly prizes with Vodacom Fantasy!
      </p>
    </div>
    <div class="d-flex flex-column justify-content-center mt-2">
      <p class="terms-conditions-text">
        <a href="https://termsandconditions.vodacomfantasy.com/">T's and C's Apply</a>
      </p>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import ExhibitionMatchService from "@/services/exhibitionMatchService";

export default {
  name: "MatchPredictions",
  data() {
    return {
      team1FlagWidth: 50,
      team2FlagWidth: 50
    }
  },
  computed: {
    ...mapState(['totalPredictionCount']),
  },
  methods: {
    ...mapMutations(['setTotalPredictionCount']),
    onChangePredictionsClicked() {
      this.$router.push('/match')
    },
    onSignUpClicked() {
      window.open('http://thevcfl.com', '_self');
    },
    getFlagLengths() {
      const total = this.totalPredictionCount.team1Total + this.totalPredictionCount.team2Total;
      this.team1FlagWidth = this.totalPredictionCount.team1Total / total * 100;
      this.team2FlagWidth = this.totalPredictionCount.team2Total / total * 100;
    },
  },
  async mounted() {
    const countPredictions = await ExhibitionMatchService.countExhibitionGameEntries();
    this.setTotalPredictionCount(countPredictions.data);
    this.getFlagLengths();
  }
}
</script>

<style scoped>
.percentage-real-madrid {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px 0 8px 0;
  background-color: #000000;
  color: #FFFFFF;
  padding: 2px 4px;
}

.real-madrid-flag {
  background: url("../assets/real-madrid-flag.png") no-repeat center;
  border-radius: 10px 0 0 10px;
  border: 2px #000000 solid;
  border-right: 1px #000000 solid !important;
}

.percentage-liverpool {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 8px 0 8px;
  background-color: #000000;
  color: #FFFFFF;
  padding: 2px 4px;
}

.liverpool-flag {
  background: url("../assets/liverpool-flag.png") no-repeat center;
  border-radius: 0 10px 10px 0;
  border: 2px #000000 solid;
  border-left: 1px #000000 solid !important;
}

.team-flag {
  height: 150px;
  position: relative;
}

.header-text {
  color: black;
  font-weight: 600;
  font-size: 1.5rem;
}

.body-text {
  font-weight: 500;
  font-size: larger;
  text-align: center;
}

.line-break {
  height: 2px;
  background-color: black;
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.top-margin {
  margin-top: 100px
}

.predictions-banner {
  display: flex;
  height: 150px;
  width: 80vw;
  margin-top: 50px;
}

@media (min-width: 776px) {
  .predictions-banner {
    width: 40vw !important;
  }
}

.enter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: 100px;
  width: 100%;
  background-color: black;
  border-radius: 10px 10px 10px 10px;
  color: white;
  font-weight: 600;
  border: none;
}
</style>
