<template>
  <div class="background" :style="{ backgroundImage: 'url(' + require(`./assets/background.png`) + ')'}">
    <HeaderComponent/>
    <div class="d-flex flex-column content-body justify-content-center">
      <router-view class="col-10 col-sm-7 col-md-5 col-lg-4 col-xl-3"></router-view>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent";

export default {
  components: {HeaderComponent}
}
</script>

<style>
* {
  margin: 0;
}

.background {
  z-index: -1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  -webkit-overflow-scrolling: touch !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-repeat: no-repeat;
  background-size: cover;
}

</style>
