<template>
  <div class="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
    <div class="team-logo-container">
      <img class="team-logo-img" src="../assets/real-madrid-badge.png">
      <img class="team-header-img" src="../assets/team-header.png">
      <img class="team-logo-img" src="../assets/liverpool-badge.png">
    </div>
    <div class="predictor-box">Champions League Final Predictor</div>
    <input placeholder="Mobile Number" class="msisdn-input" v-model="msisdn" v-on:change="msisdnConversion"/>
    <div class="flex-wrap text-center text-box" v-if="!isMsisdnValid">
      <span class="text-content error-message">Please enter a valid msisdn.</span>
    </div>
    <button class="enter-button" v-on:click="onEnterClicked">ENTER</button>
    <div class="d-flex flex-column justify-content-center mt-5">
      <p class="terms-conditions-text">
        <a href="https://termsandconditions.vodacomfantasy.com/">T's and C's Apply</a>
      </p>
    </div>
  </div>
</template>

<script>
import ExhibitionMatchService from "@/services/exhibitionMatchService";
import {mapMutations} from 'vuex'

export default {
  name: "LoginPage",
  data() {
    return {
      msisdn: undefined,
      isMsisdnValid: true
    }
  },
  methods: {
    ...mapMutations(['setMsisdn', 'setPlayerPredictions']),
    msisdnConversion() {
      if (this.msisdn.startsWith('0')) {
        this.msisdn = this.msisdn.slice(1);
        const saNumber = '27';
        this.msisdn = parseInt(saNumber + this.msisdn, 10);
      } else if (this.msisdn.startsWith('+')) {
        this.msisdn = this.msisdn.slice(1);
      }
    },
    async onEnterClicked() {
      if (this.msisdn.toString().length === 11) {
        this.setMsisdn(this.msisdn);
        const checkExhibitionGameEntriesResponse = await ExhibitionMatchService.checkExhibitionGameEntries(this.msisdn);
        if (checkExhibitionGameEntriesResponse.data) {
          this.setPlayerPredictions(checkExhibitionGameEntriesResponse.data);
          this.$router.push('/predictions');
        } else {
          this.$router.push('/match');
        }
      } else {
        this.isMsisdnValid = false;
      }
    }
  }
}
</script>

<style scoped>
.team-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

@media (min-width: 776px) {
  .team-logo-container {
    height: 200px;
  }
}

.team-logo-img {
  height: 100%;
  margin: 10px;
}

.team-header-img {
  height: 70%;
}

.msisdn-input {
  text-align: center;
  margin-top: 50px;
  width: 90%;
  height: 40px;
  box-shadow: 0px 2px 9px 4px darkgrey;
  border-radius: 10px;
  border-style: none;
}

.predictor-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-top: 20px;
  width: 80%;
  background-color: black;
  border-radius: 10px 10px 10px 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  color: white;
  font-weight: 600;
  padding: 2px;
}

@media (max-width: 360px ) {
  .predictor-box {
    font-size: xx-small;
  }
}

.enter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: 50px;
  width: 100%;
  background-color: black;
  border-radius: 10px 10px 10px 10px;
  color: white;
  font-weight: 600;
  border: none;
}

.error-message {
  color: #CE0000;
}

</style>
