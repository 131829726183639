<template>
  <img v-if="windowWidth < 776" class="header-icon-bottom" src="../assets/header-icon.png"/>
  <div class="header-container" v-if="windowWidth > 776">
    <img class="header-logo" src="../assets/main-fantasy-header-2.png"/>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  async mounted() {
    window.addEventListener('resize', () => this.windowWidth = window.innerWidth);
  }
}
</script>

<style scoped>
.header-icon-bottom {
  width: 100vw;
  position: fixed;
  top: 0;
}

@media screen and ( min-width: 776px ) {
  .header-logo {
    height: 10vh;
  }
}

.header-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: white;
  position: fixed;
  top: 0;
}
</style>
